.demo-editor {
    min-height: 275px;
    border: 1px solid #f1f1f1;
    padding: 5px;
    border-radius: 2px;
}

.avatar-image {
    width: 80px;
    height: 80px;
    overflow: hidden;
    margin: auto;
    border-radius: 50%;
}

.primary {
    color: #D1232A;
}
