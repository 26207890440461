@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
body {
  word-break: break-word;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.card-title,
.text-title,
.table,
.form-group label,
.form-control {
  color: #333;
}
.cursorPointer {
  cursor: pointer;
}
.colorRed {
  color: #d1232a;
}
.listResultBlk,
.detailTopBlk img,
.messageItems li,
.chatDetailTopTxt > * {
  transition: all 0.3s;
}
h1,
.h1 {
  font-size: 2em;
}
h2,
.h2 {
  font-size: 1.5em;
}
h3,
.h3 {
  font-size: 1.17em;
}
.main-content-wrap {
  min-height: calc(100% - 80px);
}
.main-content {
  padding-bottom: 30px;
}
.layout-sidebar-large .main-header {
  background: #000;
}
.layout-sidebar-large .main-header .menu-toggle div {
  background: #d1232a;
  height: 2px;
}
.layout-sidebar-large .main-header .logo {
  width: 320px;
}
.layout-sidebar-large .main-header .logo img,
.auth-logo img {
  width: 100%;
  height: auto;
  max-width: 300px;
}
.loginWrap {
  background-image: url(../images/login-bg.jpg);
  background-position: center center;
}
.auth-layout-wrap .auth-content {
  min-width: 0;
  width: 400px;
  max-width: 100%;
}
.jobWrapper {
  display: flex;
}
.jobWrapper .leftContent {
  padding-right: 30px;
  flex: 1;
}
.jobWrapper .rightContent {
  flex: 0 0 300px;
  width: 300px;
  font-family: "Noto Sans", sans-serif;
  color: #1a1a1a;
  font-size: 0.875rem;
  line-height: 1.5;
}
.jobWrapper .rightContent ul {
  padding: 0;
}
.jobWrapper .rightPreviewWrap {
  background: url(../images/iphone.png) no-repeat 0 0 / 100%;
  width: 100%;
  height: 620px;
  padding: 40px 20px 60px;
  display: flex;
}
.jobWrapper .leftContent .card-body {
  max-height: 600px;
  overflow-y: auto;
}
.rightPreviewWrap .previewContent {
  flex: 1;
}
.form-group .custom-switch {
  margin-top: 10px;
}
.preListView,
.listContent,
.preDetailView,
.detailContentBlk {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.listTopBlk {
  padding: 12px;
  box-shadow: 0 4px 8px -4px rgba(26, 26, 26, 0.2);
  position: relative;
  z-index: 1;
}
.listContent {
  background: #f1f1f1;
  padding: 16px 12px 0;
  flex: 1;
}
.listResultBlk {
  background: #fff;
  border-radius: 4px;
  padding: 12px 12px 8px;
  margin-bottom: 14px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
  color: #656565;
  cursor: pointer;
}
.listResultBlk:hover {
  box-shadow: 0 0 5px rgba(26, 26, 26, 0.15);
}
.listPlaceholder {
  flex: 1;
  background: url(../images/job-bg.png) repeat-y 0 0 / 100%;
}
.listResultBlk > * {
  margin-bottom: 6px;
}
.listResultBlk .jobTitle {
  flex: 0 0 100%;
  font-size: 0.875rem;
  font-weight: 700;
  color: #1a1a1a;
}
.previewContent .jobLocation {
  background: url(../images/icon-map-pin.svg) no-repeat 0 center;
  padding: 0 12px 0 18px;
}
.previewContent .contentTopMain .jobLocation {
  display: inline-block;
}
.previewContent .jobTime {
  background: url(../images/icon-clock.svg) no-repeat 0 center;
  padding: 0 0 0 20px;
}
.placeholderBlk {
  opacity: 0.4;
}
.detailTopBlk {
  background: #19647e;
  padding: 12px;
  color: #fff;
  display: flex;
}
.detailTopBlk img {
  cursor: pointer;
  margin: 0 16px 0 0;
  width: 14px;
}
.detailTopBlk img:hover {
  opacity: 0.5;
}
.detailContentBlk {
  padding: 16px 12px;
  overflow-y: auto;
}
.contentTopMain {
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 14px;
  margin-bottom: 14px;
  text-align: center;
  color: #656565;
  font-size: 0.75rem;
}
.jobHeading {
  font-size: 1.125rem;
  line-height: 1.25;
  font-weight: 700;
}
.otherTags {
  display: flex;
  flex-wrap: wrap;
}
.otherTags {
  list-style-type: none;
  justify-content: center;
  margin: 8px 0;
}
.otherTags li {
  background: #e4e4e4;
  color: #1a1a1a;
  border-radius: 4px;
  margin: 4px;
  padding: 2px 6px;
  font-size: 12px;
  text-align: center;
}
.detailDescContent {
  font-size: 0.75rem;
}
.detailDescContent p:empty,
.detailDescContent p > br:only-child {
  display: none;
}
.detailDescContent h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
}
.detailDescContent p {
  margin-bottom: 8px;
}
.detailDescContent ul {
  margin: 0 0 12px 16px;
}
.detailDescContent ul li {
  margin-bottom: 5px;
}
.descHeading {
  font-weight: 700;
  margin-bottom: 12px;
}
.detailEnquire {
  padding: 12px 16px 0;
  text-align: center;
  background: #fff;
  box-shadow: 0 -4px 8px 0 rgba(26, 26, 26, 0.15);
}
.detailEnquire > div {
  background: #19647e;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  padding: 10px;
}
.detailContentBlk::-webkit-scrollbar {
  width: 4px;
}
.detailContentBlk::-webkit-scrollbar-track {
  background: #c7c7c7;
  border-radius: 5px;
}
.detailContentBlk::-webkit-scrollbar-thumb {
  background: #19647e;
  border-radius: 5px;
}
.detailContentBlk::-webkit-scrollbar-thumb:hover {
  background: #0f475a;
}
.checkbox span,
.radio span {
  display: inline-block;
  line-height: 20px;
}
.inlineFlexContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.form-group .inlineFlexContainer > * {
  margin: 0 15px 10px 0;
}
.pageHeadingWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
  align-items: center;
}
.pageHeadingWrap > *:not(:last-child) {
  margin-right: 16px;
}
.notiText {
  margin: -10px 0 10px;
  text-align: right;
  color: #d1232a;
}
.btn-primary:focus,
.btn-primary:hover,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  box-shadow: none;
  background: #333;
  border-color: #000;
}
.btn.disabled,
.btn.disabled:hover,
.btn:disabled:hover,
.btn:disabled {
  cursor: not-allowed;
  background: #bbb;
  border-color: #bbb;
}
.notification {
  padding: 10px 10px 10px 40px;
}
.notification:before {
  left: 8px;
}
.notification-container {
  right: 50%;
  transform: translateX(50%);
  width: 300px;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item,
.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active,
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold,
.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item.active
  .nav-item-hold {
  color: inherit;
}
.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item
  .active.nav-item-hold {
  color: #d1232a;
}
.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item.active
  .triangle {
  display: none;
}
.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item
  a.active
  + .triangle {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 0 0 30px 30px;
  border-color: transparent transparent #d1232a transparent;
}
.form-control {
  padding: 0.25rem 0.5rem;
}
select.form-control {
  background: #f8f9fa url(../images/arrow-down.svg) no-repeat calc(100% - 10px)
    center;
  padding-right: 24px;
}
.rdt .form-control:disabled,
.rdt .form-control[readonly],
.rdt .form-control {
  background: #f8f9fa url(../images/icon-calendar.svg) no-repeat
    calc(100% - 6px) center / 16px;
  padding-right: 24px;
  cursor: pointer;
}
.layout-sidebar-large .main-header .header-part-right .userInitial {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #d1232a;
}
.dropdown-menu {
  padding: 6px 0;
}
.dropdown-header {
  font-size: inherit;
}
.dropdown-header,
.dropdown-item {
  padding: 6px 16px;
}
.customizer {
  top: 160px;
  max-height: calc(100% - 180px);
  display: flex;
}
.customizer.addMessage {
  top: 110px;
  right: -600px;
  width: 600px;
}
.customizer.addMessage.open {
  right: 0;
}
.customizer .rightFilter {
  display: flex;
  width: 100%;
}
.customizer .rightFilter .card {
  width: 100%;
}
.customizer .rightFilter .collapse {
  overflow-y: auto;
}
.customizer .handle,
.customizer .handle:hover,
.customizer.addMessage .handle,
.customizer.addMessage .handle:hover {
  background: #333 url(../images/filter-icon.png) no-repeat center center / 14px;
  width: 30px;
  height: 30px;
  left: -30px;
  font-size: 0;
  color: #d1232a;
  z-index: 9;
  padding: 5px 8px;
  transition: none;
}
.customizer.addMessage .handle,
.customizer.addMessage .handle:hover {
  background: #d1232a url(../images/icon-add-white.svg) no-repeat center center /
    14px;
}
.accordion.rightFilter > .card > .card-header {
  margin-bottom: 0;
}
.customizer .handle:hover {
  background-color: #000;
}
.customizer.open .handle,
.customizer.open .handle:hover {
  background: transparent;
  color: #fff;
  font-size: 12px;
  padding: 5px 0;
  box-shadow: none;
  width: auto;
  left: auto;
  right: 20px;
  border-radius: 4px;
}
.customizer .handle span {
  display: none;
  font-size: 12px;
  line-height: 20px;
}
.customizer.open .handle span {
  display: block;
}
.card-header {
  background: #333;
  color: #fff;
}
.accordionWrap .card .card-header {
  background: #333 url(../images/icon-plus.svg) no-repeat calc(100% - 16px)
    center;
  border-radius: 10px;
}
.accordionWrap .accShow .card-header {
  background-image: url(../images/icon-minus.svg);
  border-radius: 10px 10px 0 0;
}
.accordionWrap .accordion:not(:last-child) {
  margin-bottom: 16px;
}
.accordionWrap .accordion > .card {
  overflow: initial;
}
.form-group {
  margin-bottom: 1rem;
}
.form-group:last-child {
  margin-bottom: 0;
}
.table th,
.table td {
  padding: 8px;
}
.table thead th {
  background: #333;
  color: #fff;
}
.table .dateCol {
  width: 120px;
}
.table .phoneCol {
  width: 130px;
}
.table .sourceCol {
  width: 100px;
}
.table .otherTags {
  margin: 0;
  padding: 0;
}
.table tr.inactiveRow {
  background: rgb(0, 0, 0, 5%);
}
.table tr.expiredRow {
  background: rgb(209, 35, 42, 10%);
}
.table tr.inactiveRow .otherTags li {
  background: rgb(0, 0, 0, 15%);
}
.table tr.expiredRow .otherTags li {
  background: rgb(209, 35, 42, 20%);
}
.tblJobTitle {
  font-weight: 600;
}
.addressLabel {
  margin-top: 12px;
}
.btnWrap {
  display: flex;
  align-items: center;
}
.flexleftGap {
  margin-left: auto;
}
.btnWrap > *:not(:last-child) {
  margin-right: 16px;
}
.accordionWrap .accordion > .card,
.accordionWrap .accordion .card:last-of-type,
.accordionWrap .accordion .card:first-of-type {
  border-radius: 10px;
}
.rightFilter.accordion .card {
  border-radius: 10px 0 0 10px;
}
.profileImgWrap {
  display: flex;
  align-items: center;
}
.profileImg {
  flex: 0 0 40px;
  margin-right: 20px;
  box-shadow: 0 0 5px rgb(0, 0, 0, 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #d1232a;
  position: relative;
}
.profileImg,
.profileImg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.deleteImg {
  background: #d1232a url(../images/icon-close.svg) no-repeat center center /
    100%;
  position: absolute;
  font-size: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #d1232a;
  border-radius: 50%;
  cursor: pointer;
  right: -12px;
  top: 0;
}
.jobDocTblWrap {
  max-height: 340px;
  overflow: auto;
  margin-bottom: 16px;
  position: relative;
  border: 1px solid #e4e4e4;
}
.jobDocTblWrap thead th {
  position: sticky;
  top: 0;
}
.jobApplicationTbl {
  width: 100%;
}
.jobApplicationTbl > thead > tr > th {
  text-align: center;
  background: #e4e4e4;
  color: inherit;
}
.jobApplicationTbl > tbody > tr > td,
.jobApplicationTbl > thead > tr > th {
  padding: 8px 4px;
  border-bottom: 1px solid #e4e4e4;
}
.actionCol {
  width: 85px;
  text-align: center;
}
.nameCol,
.resendCol {
  width: 180px;
}
.jobApplicationTbl .docCol {
  width: 150px;
  text-align: center;
}
.jobApplicationTbl .dateCol {
  min-width: 130px;
}
.jobApplicationTbl .appStatusCol {
  min-width: 150px;
}
.jobApplicationTbl .locationCol {
  min-width: 300px;
  width: 20%;
}
.jobApplicationTbl .dateCol,
.jobApplicationTbl .appStatusCol {
  width: 20%;
}
.jobApplicationTbl.haveLocationFilter .dateCol,
.jobApplicationTbl.haveLocationFilter .appStatusCol {
  width: 15%;
}
.actionItems {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d1232a;
}
.actionItems i {
  font-size: 16px;
  cursor: pointer;
}
.addIcon,
.viewIcon,
.cloneIcon,
.deleteIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 0 4px;
  font-size: 0;
}
.addIcon {
  background: url(../images/icon-add.svg) no-repeat center center / 80%;
}
.deleteIcon {
  background: url(../images/icon-delete.svg) no-repeat center center / 100%;
}
.viewIcon {
  background: url(../images/icon-view.svg) no-repeat center center / 100%;
}
.cloneIcon {
  background: url(../images/icon-clone.svg) no-repeat center center / 100%;
}
.candidateNameCol {
  display: flex;
  align-items: center;
}
.candidateNameCol .profileImg,
.candidateNameCol .profileImg img {
  width: 28px;
  height: 28px;
}
.candidateNameCol .profileImg {
  margin-right: 8px;
  flex: 0 0 28px;
  font-size: 16px;
  line-height: 24px;
  background: #d1232a;
  color: #fff;
}
.tblVMid .table td {
  vertical-align: middle;
}
.dateColWrap {
  margin-left: -8px;
  margin-right: -8px;
}
.dateColWrap [class*="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}
.candidateDocWrap {
  display: flex;
}
.candidateDocWrap > * {
  flex: 0 0 calc(50% - 15px);
}

.dragDrop {
  background: #f8f8f8 no-repeat 10px center / 16px;
  padding: 16px 10px;
  text-align: center;
  border: 1px dashed #c7c7c7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
  flex: 1;
}
.dragDrop.active {
  border-color: #d1232a;
  background: rgb(209 35 42 / 5%);
}
.dragDrop strong {
  color: #d1232a;
  font-weight: 600;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
}
.smlTxt {
  font-size: 12px;
}
.generatePassword {
  font-size: 12px;
  cursor: pointer;
  color: #d1232a;
}
.chat-sidebar-container .chat-sidebar-wrap {
  width: 400px;
}
.chat-sidebar-wrap.hideMobile {
  left: -400px;
}
.chat-content-wrap.hideMobile {
  margin-left: 400px;
}
.chat-input-area,
.chat-content {
  border-top: 1px solid #e4e4e4;
}
.chooseTemplate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.chooseTemplate .form-control {
  width: 200px;
  margin-right: 16px;
}
.expendarArea {
  cursor: pointer;
  color: #d1232a;
}
.messageItems {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.messageItems li {
  border-top: 1px solid #e4e4e4;
  padding: 10px 16px;
  position: relative;
  font-size: 12px;
  color: #333;
  cursor: pointer;
}
.messageItems li:hover,
.messageItems li.active {
  background: #f1f1f1;
}
.messageItems li > *,
.chatDetailTopTxt > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
}
.messageItems li > *:not(:last-child) {
  margin-bottom: 8px;
}
.messageDate {
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: right;
  max-width: 70px;
  color: #000;
}
.messageItems .jobTitle {
  padding-right: 70px;
}
.candidateName {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
}
.messageItems li > .lastMessage {
  margin-bottom: 0;
  color: #777;
}
.chatAreaContainer {
  padding: 16px;
}
.chatBox {
  position: relative;
}
.chatBox button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 4px 4px 0;
}
.chat-sidebar-container .chat-content-wrap .chat-content .user .message {
  background: rgb(209 35 42 / 10%);
  margin-left: 16px;
}
.chat-sidebar-container .chat-content-wrap .chat-content .user .message:before {
  border-color: transparent transparent rgb(209 35 42 / 10%) transparent;
}
.chatDetailTopBar {
  max-width: 100%;
  display: flex;
}
.chatDetailTopBar .profileImg {
  margin-top: -2px;
}
.chatDetailTopTxt > * {
  cursor: pointer;
}
.chatDetailTopTxt > *:hover {
  color: #d1232a;
}
.chatDetailTopTxt a {
  color: inherit;
}
.chat-content .profileImg {
  margin: 0;
}
.chat-content .message + .profileImg {
  margin-left: 10px;
}
.chatNote {
  font-size: 11px;
  color: #656565;
  font-style: italic;
  text-align: right;
  margin-top: 5px;
}
.chat-sidebar-container .chat-content-wrap .chat-content {
  height: calc(100vh - 355px);
}
.chat-sidebar-container .chat-content-wrap .chat-content.templateActive {
  height: calc(100vh - 455px);
}
.templateActive + .chatAreaContainer textarea.form-control {
  height: 167px;
}
.chatAreaContainer textarea.form-control {
  padding-right: 50px;
}
.resetFilterBtn {
  float: right;
  margin-right: 20px;
  margin-top: 7px;
  cursor: pointer;
  font-size: 12px;
  color: #d1232a;
}
.ql-snow .ql-tooltip {
  z-index: 999;
}
.jobWrapper .leftContent .card-body .ql-snow .ql-tooltip {
  left: 50% !important;
  transform: translateX(-50%);
}
.supportWrap {
  background: linear-gradient(
      220deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.9) 100%
    ),
    url(../images/support-bg.jpg) no-repeat center center / cover;
}
.supportBlk {
  margin: 20px 0;
  width: 90%;
  max-width: 600px;
  padding: 1.5rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.5);
}
.supportBlk .row {
  margin-left: -8px;
  margin-right: -8px;
}
.supportBlk .row {
  margin-left: -8px;
  margin-right: -8px;
}
.supportBlk [class*="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}
.supportBlk textarea {
  height: 70px;
}
.supportFooter {
  background: #222;
  margin: 1.5rem -1.5rem -1.5rem;
  padding: 1.5rem;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 0 0 12px 12px;
}
.supportFooter a {
  color: inherit;
}
.supportFooter a:hover {
  color: #d1232a;
}
.supportFooter ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
}
.supportFooter ul li {
  margin: 0 8px;
}
.supportFooter ul li a {
  color: #d1232a;
}
.supportFooter ul li a:hover {
  color: #fff;
}
.successMsg {
  margin-top: 20px;
  background: #080;
  color: #fff;
  padding: 5px 10px;
  text-align: center;
}
.legalWrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 40px 30px;
}
.legalWrap p {
  margin-bottom: 20px;
}
.legalWrap h2,
.legalWrap h3,
.legalWrap ul,
.legalWrap ol {
  margin-bottom: 30px;
  margin-top: 40px;
}
.legalWrap ul,
.legalWrap ol,
.legalWrap h3 {
  margin-top: 20px;
}
.legalWrap h2:first-child,
.legalWrap h3:first-child,
.legalWrap ul:first-child,
.legalWrap ol:first-child {
  margin-top: 0;
}
.legalWrap li {
  margin-bottom: 16px;
}
.legalWrap li:last-child,
.legalWrap p:last-child {
  margin-bottom: 0;
}
.legalWrap h2 .subTxt {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  margin-top: 5px;
}
.css-1pahdxg-control {
  border-color: #eb888c !important;
  box-shadow: 0 0 0 0.2rem rgb(209 35 42 / 25%) !important;
}
.css-tj5bde-Svg {
  width: 14px;
  fill: #1a1a1a;
  opacity: 0.5;
}
.css-tlfecz-indicatorContainer {
  color: #333 !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-12jo7m5,
.peopleListItem > div {
  display: flex !important;
  align-items: center;
}
.css-12jo7m5 .profileImg,
.peopleListItem > div .profileImg {
  margin-right: 6px;
  flex: 0 0 24px;
  font-size: 14px;
  background: #fff;
}
.css-12jo7m5 .profileImg,
.peopleListItem > div .profileImg,
.css-12jo7m5 .profileImg img,
.peopleListItem > div .profileImg img {
  border-radius: 4px;
  width: 24px;
  height: 24px;
}
.css-12jo7m5 .profileImg {
  box-shadow: none;
}
.peopleListItem > div label {
  margin: 2px 0 0 5px;
}
.addMessage .chatBox .form-control {
  height: 130px;
  resize: none;
}
#react-select-2-listbox > div {
  max-height: 200px;
}
@media (max-width: 1000px) {
  .jobWrapper .leftContent {
    padding: 0;
  }
  .jobWrapper .rightContent {
    display: none;
  }
  .jobWrapper .leftContent .card-body {
    max-height: 9999em;
  }
}
@media (max-width: 576px) {
  .layout-sidebar-large .main-header .logo {
    width: 200px;
  }
  .layout-sidebar-large .sidebar-left .navigation-left {
    width: 100px;
  }
  .layout-sidebar-large
    .sidebar-left
    .navigation-left
    .nav-item
    .nav-item-hold {
    padding: 16px 0;
  }
  .dateColWrap {
    margin-left: -5px;
    margin-right: -5px;
  }
  .dateColWrap [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .supportFooter {
    font-size: 10px;
    padding: 24px 12px;
  }
}
